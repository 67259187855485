import { BoxProps, LinkProps, Flex, Text, Button, Box } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useState, useRef } from 'react'

import { useHydrationFriendlyAsPath } from '@miimosa/common'
import type { CountryCode } from '@miimosa/common/types'
import ChevronDown from '@miimosa/design-system/components/icons/ChevronDown'
import * as Flags from '@miimosa/design-system/components/icons/flags'
import Link from '@miimosa/design-system/components/Links/Link'
import { usePlatform, useFocusExit } from '@miimosa/design-system/lib/hooks'

const PlatformNameAndFlag = ({ country }: { country: CountryCode }) => {
  const Flag = Flags[country]
  const { t } = useTranslation()

  return (
    <>
      <Flag />
      <Text ml={2}>{t(`countries.${country}`)}</Text>
    </>
  )
}

interface PlatformLinkProps extends LinkProps {
  country: CountryCode
  route: string
}

const PlatformLink = ({ country, route, ...props }: PlatformLinkProps) => (
  <Link
    locale={country === 'fr' ? 'fr' : 'fr-BE'}
    href={route}
    display="flex"
    flexDirection="row"
    py="4px"
    color="main_gray"
    _hover={{
      color: 'dark_green',
      fontWeight: 700,
    }}
    {...props}
    sx={{
      '&:hover': {
        '.plathigh': {
          visibility: 'visible',
        },
      },
    }}
  >
    <Box w="4px" mr="10px" bg="dark_green" visibility="hidden" borderLeftRadius="4px" className="plathigh" />
    <PlatformNameAndFlag country={country} />
  </Link>
)

const PlatformSelect = ({ ...props }: BoxProps) => {
  const { platform } = usePlatform()
  const asPath = useHydrationFriendlyAsPath()
  const [showLinks, setShowLinks] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const onBlur = useFocusExit(containerRef, (_, inside) => {
    if (!inside) setShowLinks(false)
  })

  return (
    <Box {...props} position="relative" color="white" ref={containerRef} onBlur={onBlur}>
      <Button
        variant="ghost"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        p="6px"
        borderBottom="1px"
        borderColor="white"
        w="full"
        onClick={() => setShowLinks(!showLinks)}
      >
        <PlatformNameAndFlag country={platform} />
        <ChevronDown
          ml="auto"
          transition="transform 0.2s ease-out"
          transform={showLinks ? 'rotate(180deg)' : undefined}
        />
      </Button>

      <Flex
        visibility={showLinks ? 'visible' : 'hidden'}
        direction="column"
        position="absolute"
        top="100%"
        left="0"
        w="full"
        px="6px"
        py="4px"
        bg="white"
      >
        <PlatformLink country="fr" route={asPath} />
        <PlatformLink country="be" route={asPath} />
      </Flex>
    </Box>
  )
}
export default PlatformSelect
