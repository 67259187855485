export interface Link {
  text: string
  href?: string
  route?: string
}

export interface Section {
  title: string
  links: Link[]
}

export const columns: Section[][] = [
  [
    { title: 'donation', links: [{ text: 'projects_donation', route: '/projects?type=donation' }] },
    {
      title: 'lending',
      links: [
        { text: 'projects_lending', route: '/projects?type=lending' },
        // { text: 'lending_stats', route: '/lending_stats' },
        { text: 'lending_test', href: '/lending_capacity_simulator' },
      ],
    },
    { title: 'finance', links: [{ text: 'finance_solution', route: '/create' }] },
  ],
  [
    {
      title: 'discover',
      links: [
        { text: 'manifest', route: '/manifest' },
        { text: 'testimonies', route: '/testimony' },
        { text: 'partnership', route: '/partnership' },
        { text: 'partners', route: '/partners' },
        { text: 'join_us', href: 'https://www.welcometothejungle.com/fr/companies/miimosa' },
        { text: 'blog', href: 'https://blog.miimosa.com/' },
      ],
    },
  ],
  [
    {
      title: 'legal_notice',
      links: [
        {
          text: 'cgu_miimosa',
          href: 'https://mii-bkt-marketing-prod.s3.eu-central-1.amazonaws.com/site-content/CGU_MiiMOSA_2021_v02.09.2021.pdf#page=',
        },
        { text: 'cgu_mangopay', href: 'https://www.mangopay.com/terms/PSP/PSP_MANGOPAY_FR.pdf' },
        {
          text: 'legal_notice',
          href: 'https://mii-bkt-marketing-prod.s3.eu-central-1.amazonaws.com/site-content/CGU_MiiMOSA_2021_v02.09.2021.pdf#page=24',
        },
        {
          text: 'personal_data',
          href: 'https://mii-bkt-marketing-prod.s3.eu-central-1.amazonaws.com/site-content/CGU_MiiMOSA_2021_v02.09.2021.pdf#page=13',
        },
        { text: 'cookies', href: '/cookies' },
      ],
    },
  ],
  [
    {
      title: 'help',
      links: [
        {
          text: 'help',
          href: '/help_center',
        },
      ],
    },
    {
      title: 'team',
      links: [
        {
          text: 'phone',
        },
        {
          text: 'email',
        },
      ],
    },
  ],
]
