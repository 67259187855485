import { useRouter } from 'next/router'
import { createContext, useReducer } from 'react'

import { usePlatform } from '@miimosa/design-system'

import { formatQueryFilters } from '@helpers/getFilters'

export const FiltersContext = createContext<any | undefined>(undefined)
export const FiltersDispatchContext = createContext<any | undefined>(undefined)
//TODO typing

const FiltersProvider = ({ children }: { children: JSX.Element }) => {
  const { query } = useRouter()
  const { platform } = usePlatform()

  const reducer = (state: any, action: any) => {
    if (action.type === 'add') {
      const newFilters = {
        ...state.filters,
        [action.categoryId]: [...state.filters[action.categoryId], action.newFilter],
      }
      return { filters: newFilters, shouldResetPage: true }
    }
    if (action.type === 'remove') {
      const newFilters = {
        ...state.filters,
        [action.categoryId]: state.filters[action.categoryId].filter((item: string) => item !== action.newFilter),
      }
      return { filters: newFilters, shouldResetPage: true }
    }
    if (action.type === 'addRadio') {
      const newFilters = { ...state.filters, [action.categoryId]: [action.newFilter] }
      return { filters: newFilters, shouldResetPage: true }
    }
    if (action.type === 'clear') {
      const newFilters = Object.entries(state.filters)
        .map(([catId]) => [catId, []])
        .reduce((acc, [cat, data]) => {
          if (cat == 'type') {
            return { ...acc, ...{ [cat as string]: ['all'] } }
          }
          return { ...acc, ...{ [cat as string]: data } }
        }, {})

      return { filters: newFilters, shouldResetPage: true }
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    filters: formatQueryFilters(query, platform),
    shouldResetPage: false,
  })

  return (
    <FiltersContext.Provider value={state}>
      <FiltersDispatchContext.Provider value={dispatch}>{children}</FiltersDispatchContext.Provider>
    </FiltersContext.Provider>
  )
}

export default FiltersProvider
