import { Button, Container, Flex, Link, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { RefObject, useEffect, useRef, useState } from 'react'

import {
  CommentsType,
  FrontAssetType,
  FrontCollectGoalType,
  FrontCollectTypeType,
  FrontPartnerLogoType,
  FrontRewardType,
  FrontTermFrequencyType,
  FullArticleType,
  Subscription,
  articleType,
  contributorsType,
  navBarType,
} from '@miimosa/common'
import { toV2Link, usePlatform } from '@miimosa/design-system'

import {
  Articles,
  Comments,
  Contributors,
  Description,
  Documents,
  Finance,
  InterestsCalculator,
  LendingRisk,
  ProjectNavBar,
} from '@components'

import RewardInterface from './RewardInterface'

interface Props {
  collectType: FrontCollectTypeType
  description: string
  usageDescription: string
  facebookUrl?: string | null
  twitterUrl?: string | null
  instagramUrl?: string | null
  linkedinUrl?: string | null
  partnerLogos: FrontPartnerLogoType[]
  contributorsCount: number
  projectSlug?: string
  articlesCount: number
  commentsCount: number
  activeTab: string
  subscriptionState: Subscription['subscription'] | null
  articles?: articleType[]
  article?: FullArticleType
  financialIntroduction?: string | null
  pnl?: string | null
  balance?: string | null
  incExp?: string | null
  financialAnalysis?: string | null
  assets?: FrontAssetType[]
  fullName?: string
  siret?: string | null
  bce?: string | null
  place?: string
  rewards: FrontRewardType[]
  isPreview: boolean
  goalType: FrontCollectGoalType
  buttonRef: RefObject<HTMLButtonElement>
  termFrequency: FrontTermFrequencyType
  termQuantity: number
  interestsRatio: number
  benefitsList?: string | null
  risksList?: string | null
  comments?: CommentsType
  contributors?: contributorsType
}

const ProjectNavBarInterface = ({
  collectType,
  description,
  usageDescription,
  facebookUrl,
  twitterUrl,
  instagramUrl,
  linkedinUrl,
  partnerLogos,
  contributorsCount,
  projectSlug,
  articlesCount,
  commentsCount,
  activeTab,
  subscriptionState,
  articles,
  article,
  financialIntroduction,
  pnl,
  balance,
  incExp,
  financialAnalysis,
  assets,
  fullName,
  siret,
  bce,
  place,
  rewards,
  isPreview,
  goalType,
  buttonRef,
  termFrequency,
  termQuantity,
  interestsRatio,
  benefitsList,
  risksList,
  comments,
  contributors,
}: Props) => {
  const { t } = useTranslation('project')
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })
  const { platform, lang } = usePlatform()

  const isLending = collectType === 'lending'
  const isDonation = collectType === 'donation'
  const shouldDisplayFinance = financialIntroduction || pnl || balance || incExp || financialAnalysis
  const shouldDisplayDocuments = assets && assets.length > 0
  const showCalculator = collectType == 'lending' && termFrequency && termQuantity && projectSlug && interestsRatio

  //used to scroll back to container top (where data is displayed (description, comments, etc))
  const containerTopRef = useRef<HTMLDivElement>(null)

  const rewardMobileRef = useRef<HTMLButtonElement>(null)
  const supportMobileRef = useRef<HTMLDivElement>(null)

  const [opacityButtonSupport, setOpacityButtonSupport] = useState(0)
  const [displayButtonSupport, setDisplayButtonSupport] = useState('none')

  useEffect(() => {
    const onScroll = () => {
      if (
        rewardMobileRef &&
        rewardMobileRef.current &&
        supportMobileRef &&
        supportMobileRef.current &&
        buttonRef &&
        buttonRef.current
      ) {
        if (
          buttonRef.current.getBoundingClientRect().bottom < 80 &&
          supportMobileRef.current.getBoundingClientRect().width > 0 &&
          rewardMobileRef.current.getBoundingClientRect().top > supportMobileRef.current.getBoundingClientRect().bottom
        ) {
          setOpacityButtonSupport(1)
          setDisplayButtonSupport('block')
        } else {
          setOpacityButtonSupport(0)
          setDisplayButtonSupport('none')
        }

        if (
          buttonRef.current.getBoundingClientRect().bottom < 80 &&
          rewardMobileRef.current.getBoundingClientRect().top > window.innerHeight
        ) {
          setOpacityButtonSupport(1)
          setDisplayButtonSupport('block')
        }
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [buttonRef])

  const navBarData: navBarType = {
    projects: {
      link: '',
      key: 'projects',
      title: t('project_key'),
      count: undefined,
      display: 'flex',
      renderComponent: (
        <Description
          collectType={collectType}
          description={description}
          usageDescription={usageDescription}
          facebookUrl={facebookUrl}
          twitterUrl={twitterUrl}
          instagramUrl={instagramUrl}
          linkedinUrl={linkedinUrl}
          partnerLogos={partnerLogos}
          fullName={fullName}
          siret={siret}
          bce={bce}
          place={place}
        />
      ),
    },
    ...(isLending &&
      shouldDisplayFinance && {
        finance: {
          link: 'finance',
          key: 'finance',
          title: t('finance_key'),
          count: undefined,
          display: isLending && shouldDisplayFinance ? 'flex' : 'none',
          renderComponent: (
            <Finance
              financialIntroduction={financialIntroduction}
              pnl={pnl}
              balance={balance}
              incExp={incExp}
              financialAnalysis={financialAnalysis}
            />
          ),
        },
      }),
    ...(isDonation && {
      contributors: {
        link: 'contributors',
        key: 'contributors',
        title: t('contributors_key'),
        count: contributorsCount,
        display: isLending ? 'none' : 'flex',
        renderComponent: <Contributors contributors={contributors} />,
      },
    }),
    ...(isDonation && {
      articles: {
        link: 'articles',
        key: 'articles',
        title: t('articles_key'),
        count: articlesCount,
        display: isLending ? 'none' : 'flex',
        renderComponent: (
          <Articles slug={projectSlug || ''} articles={articles} article={article} containerTopRef={containerTopRef} />
        ),
      },
    }),
    comments: {
      link: 'comments',
      key: 'comments',
      title: t('comments_key'),
      count: commentsCount,
      display: 'flex',
      renderComponent: <Comments slug={projectSlug || ''} comments={comments} />,
    },
    ...(isLending &&
      shouldDisplayDocuments && {
        documents: {
          link: 'documents',
          key: 'documents',
          title: 'Documents',
          count: undefined,
          display: isLending && shouldDisplayDocuments ? 'flex' : 'none',
          renderComponent: <Documents documents={assets} />,
        },
      }),
  }

  return (
    <Flex direction="column" w="full">
      <Container
        variant="full"
        bg="white"
        position="sticky"
        top="0"
        zIndex="1002"
        borderBottom="1px solid"
        borderColor="light_gray"
      >
        <Container variant="boxed" w="full" px={0} py={0}>
          <ProjectNavBar
            data={navBarData}
            activeTab={activeTab}
            subscriptionState={subscriptionState}
            slug={projectSlug || ''}
            containerTopRef={containerTopRef}
            buttonRef={buttonRef}
            collectType={collectType}
            isPreview={isPreview}
          />
        </Container>
      </Container>

      <Container variant="full" bg="creamy" flexDirection="row" columnGap={6}>
        <Container
          variant="boxed"
          alignItems="flex-start"
          py={4}
          bgColor="creamy"
          h="full"
          w="full"
          ref={containerTopRef}
          flexDir={{ base: 'column', lg: 'row' }}
          columnGap={4}
        >
          {navBarData[activeTab].renderComponent}
          {/* Rewards */}
          <Flex direction="column" w="full" flexBasis="400px">
            {rewards && rewards.length > 0 && (
              <Flex
                w="full"
                direction={{ base: 'column-reverse', lg: 'row' }}
                justifyContent="space-between"
                columnGap={8}
                px={0}
              >
                <RewardInterface
                  rewards={rewards}
                  projectSlug={projectSlug || ''}
                  isPreview={isPreview}
                  collectGoalType={goalType}
                  collectType={collectType}
                  ref={rewardMobileRef}
                />
              </Flex>
            )}
            <Flex direction={'column'}>
              {showCalculator && (
                <InterestsCalculator
                  termFrequency={termFrequency}
                  termQuantity={termQuantity}
                  interestsRatio={interestsRatio}
                  slug={projectSlug || ''}
                />
              )}
              <LendingRisk benefitsList={benefitsList} risksList={risksList} />
            </Flex>
          </Flex>
          {isMobile && (
            <Flex
              position="sticky"
              bottom={0}
              w="full"
              bg="white"
              alignItems="center"
              my={3}
              py={4}
              zIndex={3}
              ref={supportMobileRef}
              opacity={opacityButtonSupport}
              transition={'opacity 0.3s ease'}
              display={displayButtonSupport}
            >
              <Link href={toV2Link(`/projects/${projectSlug}/pledges/new`, platform, lang)}>
                <Button variant="primary" size="md" w="full">
                  {isLending ? 'Investir' : 'Soutenir ce projet'}
                </Button>
              </Link>
            </Flex>
          )}
        </Container>
      </Container>
    </Flex>
  )
}

export default ProjectNavBarInterface
