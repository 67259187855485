import { BoxProps, Flex, Box } from '@chakra-ui/react'

import { useHydrationFriendlyAsPath } from '@miimosa/common'
import Link from '@miimosa/design-system/components/Links/Link'
import { usePlatform } from '@miimosa/design-system/lib/hooks'

const LangButton = ({ route, lang, selected }: { route: string; lang: string; selected: boolean }) => (
  <Link href={route} locale={lang + '-BE'} fontWeight={selected ? 700 : 400} w="22px" h="22px" textAlign="center">
    {lang.toUpperCase()}
  </Link>
)

const LangSwitch = (props: BoxProps) => {
  const { lang } = usePlatform()
  const asPath = useHydrationFriendlyAsPath()

  const isFrench = () => lang === 'fr'

  return (
    <Flex direction="row" justifyContent="flex-end" align="center" color="white" {...props}>
      <LangButton lang="fr" selected={isFrench()} route={asPath} />
      <Link
        h="20px"
        w="40px"
        borderRadius="10px"
        borderColor="white"
        borderWidth="1px"
        borderStyle="solid"
        mx={2}
        position="relative"
        href={asPath}
        locale={isFrench() ? 'nl-BE' : 'fr-BE'}
      >
        <Box
          h="16px"
          w="16px"
          bg="white"
          borderRadius="100%"
          position="absolute"
          top="1px"
          left="1px"
          transition="transform 0.3s ease-out"
          transform={isFrench() ? undefined : 'translateX(20px)'}
        />
      </Link>
      <LangButton lang="nl" selected={!isFrench()} route={asPath} />
    </Flex>
  )
}

export default LangSwitch
