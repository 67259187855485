import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { useState } from 'react'

import { UserContext, toV2Link, usePlatform } from '@miimosa/design-system'

import ComponentLink from '@components/Header/Link'

import DesktopMenuItem from './DesktopMenuItem'
import { useNavItems } from './utils'

interface SelectedTab {
  index: number
  latched: boolean
}

const DesktopNav = (props: FlexProps) => {
  const user = useContext(UserContext)
  const { t } = useTranslation('common')
  const { platform, lang } = usePlatform()
  const { route: currentRoute } = useRouter()
  const [selectedTab, setSelectedTab] = useState<SelectedTab>({ index: -1, latched: false })
  const navItems = useNavItems(user)

  return (
    <Flex direction="row" height="100%" pos="relative" {...props}>
      {navItems.map((navItem, index) => (
        <DesktopMenuItem
          isOpen={index == selectedTab.index}
          currentRoute={currentRoute}
          onMouseEnter={() => {
            if (index !== selectedTab.index) {
              setSelectedTab({ index, latched: false })
            }
          }}
          onMouseLeave={() => {
            if (index === selectedTab.index && !selectedTab.latched) {
              setSelectedTab({ index: -1, latched: false })
            }
          }}
          onBlur={() => {
            if (index === selectedTab.index) {
              setSelectedTab({ index: -1, latched: false })
            }
          }}
          key={navItem.label}
          {...navItem}
        />
      ))}
      {!user && (
        <Flex direction="column" h="100%" justifyContent="center" ml="40px">
          <ComponentLink href={toV2Link(`/authenticate`, platform, lang)} _hover={{ fontWeight: 500 }}>
            <Text size="sm">{t('header.nav.account.title')}</Text>
          </ComponentLink>
        </Flex>
      )}
    </Flex>
  )
}

export default DesktopNav
