import { Container, Divider, Box, useBreakpointValue, Flex, Heading, Text, Link } from '@chakra-ui/react'
import { useContext, useRef } from 'react'

import type {
  CommentsType,
  FrontProjectType,
  FullArticleType,
  Subscription,
  articleType,
  contributorsType,
} from '@miimosa/common/types'
import { UserContext } from '@miimosa/design-system'

import { AdminBar, ImpactsAndLendingInfos, ImpactsList, ProjectNavBarInterface, TopInformations } from '@components'

import Hero from './Hero'

interface Props {
  project: FrontProjectType
  activeTab: string
  subscriptionState: Subscription['subscription'] | null
  articles?: articleType[]
  article?: FullArticleType
  isPreview?: boolean
  isInformed?: boolean
  comments?: CommentsType
  contributors?: contributorsType
}

const LendingProjectInterface = ({
  project,
  activeTab,
  subscriptionState,
  articles,
  article,
  comments,
  contributors,
  isPreview = false,
  isInformed = false,
}: Props) => {
  const user = useContext(UserContext)
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  const buttonRef = useRef<HTMLButtonElement>(null)

  const showAdminBar = user?.is_admin || user?.id === project.userId

  return (
    <Container variant="full">
      <Container variant="boxed" py={4}>
        {showAdminBar && (
          <>
            <AdminBar slug={project.slug || ''} />
            {isMobile ? <Box my={2} /> : <Divider my={5} />}
          </>
        )}
        <TopInformations title={project.title} userFullName={project.userFullName} />
        <Hero
          imageFileName={project.description.imageFileName}
          projectId={project.id}
          projectSlug={project.slug || ''}
          shortDescription={project.description.shortDescription}
          place={project.description.place}
          progress={project.collect.progress}
          contributorsCount={project.contributorsCount}
          endsAt={project.collect.endsAt}
          collectedAmount={project.collect.collectedAmount}
          goalAmount={project.collect.goalAmount}
          goalType={project.collect.goalType}
          goalQuantity={project.collect.goalQuantity}
          collectedQuantity={project.collect.collectedQuantity}
          projectStatus={project.state}
          regionalCode={project.regionalCode}
          videoUrl={project.description.videoUrl}
          isPreview={isPreview}
          collectType={project.collect.type}
          termFrequency={project.collect.termFrequency}
          termQuantity={project.collect.termQuantity}
          interestsRatio={project.collect.interestsRatio}
          lendingProductKind={project.lendingProductKind}
          successTriggerRatio={project.collect.successTriggerRatio}
          ref={buttonRef}
        />
        {project.labels.length > 0 && <ImpactsList labels={project.labels} />}
      </Container>
      <Flex w="full" bg="creamy" direction="column">
        <Container
          id={'123'}
          variant={{ base: 'full', lg: 'boxed' }}
          w="full"
          bg={{ base: 'creamy', lg: 'white' }}
          my={6}
          py={'24px !important'}
          border="solid 1px"
          borderColor="light_gray"
          borderRadius="4px"
        >
          <ImpactsAndLendingInfos project={project} />
        </Container>
      </Flex>
      <ProjectNavBarInterface
        collectType={project.collect.type}
        description={project.description.description}
        usageDescription={project.description.usageDescription}
        facebookUrl={project.description.facebookUrl}
        twitterUrl={project.description.twitterUrl}
        instagramUrl={project.description.instagramUrl}
        linkedinUrl={project.description.linkedinUrl}
        partnerLogos={project.partnerLogos}
        contributorsCount={project.contributorsCount}
        articlesCount={project.articlesCount}
        commentsCount={project.commentsCount}
        activeTab={activeTab}
        subscriptionState={subscriptionState}
        projectSlug={project.slug || ''}
        articles={articles}
        article={article}
        financialIntroduction={project.description.financialIntroduction}
        pnl={project.description.pnl}
        balance={project.description.balance}
        incExp={project.description.incExp}
        financialAnalysis={project.description.financialAnalysis}
        assets={project.assets}
        fullName={project.userFullName}
        siret={project.siret}
        bce={project.bce}
        place={project.description.place}
        rewards={project.rewards}
        isPreview={isPreview}
        goalType={'amount'}
        buttonRef={buttonRef}
        termFrequency={project.collect.termFrequency || 'monthly'}
        termQuantity={project.collect.termQuantity || 0}
        interestsRatio={project.collect.interestsRatio || 0}
        benefitsList={project.description.benefitsList}
        risksList={project.description.risksList}
        comments={comments}
        contributors={contributors}
      />
      <Flex
        w="full"
        bg="white"
        direction="column"
        rowGap={4}
        py={6}
        px={8}
        my={8}
        border="solid 1px"
        borderColor="light_gray"
        borderRadius="4px"
      >
        <Heading size="sm">Mentions règlementaires</Heading>
        {!isInformed && (
          <Flex direction={{ base: 'column', md: 'row' }} rowGap={2}>
            <Text size="sm" fontWeight="700" minW="160px">
              Délai de réflexion
            </Text>
            <Text size="sm" textAlign="justify">
              Conformément à l’article 22 du Règlement (UE) 2020/1503 du Parlement européen et du Conseil du 7 octobre
              2020 relatif aux prestataires européens de services de financement participatif pour les entrepreneurs,
              les investisseurs non avertis disposent d’une période de réflexion de 4 jours minimum à compter du moment
              où une offre d’investissement est réalisée.
            </Text>
          </Flex>
        )}
        <Flex direction={{ base: 'column', md: 'row' }} rowGap={2}>
          <Text size="sm" fontWeight="700" minW="160px">
            Risques
          </Text>
          <Text size="sm" textAlign="justify">
            Votre investissement n&apos;est pas couvert par les systèmes de garantie des dépôts établis conformément à
            la directive 2014/49/UE du Parlement européen et du Conseil. Votre investissement n&apos;est pas non plus
            couvert par les systèmes d&apos;indemnisation des investisseurs établis conformément à la directive 97/9/CE
            du Parlement européen et du Conseil. Plus le taux est élevé, plus le risque de perte en capital ou
            d&apos;impayé des intérêts est important. Les performances passées ne préjugent pas des performances
            futures.
          </Text>
        </Flex>
        <Flex direction={{ base: 'column', md: 'row' }} rowGap={2}>
          <Text size="sm" fontWeight="700" minW="160px">
            Conflit d&apos;intérêt
          </Text>
          <Flex direction="column" w="full">
            <Text size="sm" textAlign="justify">
              MiiMOSA s&apos;engage à n&apos;avoir aucune participation dans une quelconque offre de financement
              participatif sur sa plateforme.
            </Text>
            <Link
              href="https://miimosa.s3.eu-west-1.amazonaws.com/Gestion+des+conflits+d'inte%CC%81re%CC%82ts.pdf"
              target="_blank"
            >
              <Text size="sm" textDecoration="underline">
                Voir notre Politique de gestion des conflits d&apos;intérêts.
              </Text>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  )
}

export default LendingProjectInterface
