import { useBreakpointValue, Flex, Heading } from '@chakra-ui/react'
import { ForwardedRef, forwardRef, useEffect } from 'react'

import type { FrontCollectGoalType, FrontCollectTypeType, FrontRewardType } from '@miimosa/common'
import { Carousel } from '@miimosa/design-system'

import { DefaultReward, RewardItem } from '@components'

import RewardList from './RewardList'

interface Props {
  rewards: FrontRewardType[]
  isPreview?: boolean
  projectSlug?: string
  collectGoalType: FrontCollectGoalType
  collectType: FrontCollectTypeType
}

const RewardInterface = forwardRef(function RewardInterface(
  { rewards, isPreview = false, projectSlug, collectGoalType, collectType }: Props,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  useEffect(() => {
    if (rewards && rewards.length > 0) {
      rewards.sort((a, b) => Number(b.star) - Number(a.star))
    }
  }, [rewards])

  const renderRewards = (reward: FrontRewardType, i: number) => {
    const {
      amount,
      quantity,
      description,
      availableQuantity,
      deliveryAt,
      id,
      title,
      shippingMode,
      shippingDetails,
      isAvailable,
      takenQuantity,
      star,
      imageFileName,
    } = reward
    return (
      <Flex key={i} mx={{ base: 1, lg: 0 }}>
        <RewardItem
          amount={amount}
          quantity={quantity}
          description={description}
          availableQuantity={availableQuantity}
          deliveryAt={deliveryAt}
          rewardId={id}
          title={title}
          shippingMode={shippingMode}
          shippingDetails={shippingDetails}
          isAvailable={isAvailable}
          takenQuantity={takenQuantity}
          linkTo={`/projects/${projectSlug}/pledges/new`}
          imageFileName={imageFileName}
          star={star}
          isPreview={isPreview}
        />
      </Flex>
    )
  }
  return isMobile ? (
    <Flex direction="column" alignItems="center" rowGap={8} my={4} w="full">
      <Heading w="full" textAlign="center">
        Choisissez une contrepartie
      </Heading>
      <Carousel
        isDark
        SlideComponent={renderRewards}
        data={rewards}
        draggable
        navBottom
        boxedControls={true}
        infinite={false}
        hasDots={false}
        withArrow={false}
        centerMode={true}
        centerPadding="30px"
      />
      {collectGoalType !== 'quantity' && (
        <Flex direction="column" w="full" rowGap={4} alignItems="center">
          <DefaultReward projectSlug={projectSlug} isPreview={isPreview} collectType={collectType} ref={ref} />
        </Flex>
      )}
    </Flex>
  ) : (
    <RewardList
      rewards={rewards}
      isPreview={isPreview}
      slug={projectSlug || ''}
      collectGoalType={collectGoalType}
      collectType={collectType}
    />
  )
})

export default RewardInterface
