import { Flex, Heading } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import { FrontLendingProductKindType } from '@miimosa/common'
import type { FrontCollectGoalType, FrontCollectTypeType, FrontTermFrequencyType } from '@miimosa/common/types'

import { DotColor } from '@components/Header/DesktopMenuItem'

interface Props {
  collectType: FrontCollectTypeType
  goalType: FrontCollectGoalType
  termQuantity?: number | null
  termFrequency?: FrontTermFrequencyType | null
  interestsRatio?: number | null
  lendingProductKindType: FrontLendingProductKindType
}

const HeadingWithDot = ({
  collectType,
  goalType,
  termQuantity,
  termFrequency,
  interestsRatio,
  lendingProductKindType,
}: Props) => {
  const { t } = useTranslation('project', { keyPrefix: 'collect' })
  const isDonation = collectType === 'donation'

  return (
    <Flex direction="row" alignItems="center">
      <DotColor dotColor={isDonation ? 'green' : 'blue'} />
      {isDonation ? (
        goalType == 'quantity' ? (
          <Heading>Prévente</Heading>
        ) : (
          <Heading>Don avec contrepartie</Heading>
        )
      ) : (
        <Heading>{`${t(`lending.title.${lendingProductKindType}`)} - ${termQuantity} ${t(
          `lending.period_${termFrequency}`
        )} - ${((interestsRatio || 0) * 100).toFixed(2).replace(/\./g, ',')}%`}</Heading>
      )}
    </Flex>
  )
}

export default HeadingWithDot
