import { Button, Flex, Input, BoxProps } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'

import { Icon, usePrevious } from '@miimosa/design-system'
import { Search } from '@miimosa/design-system/components/icons'
import useFocusExit from '@miimosa/design-system/lib/hooks/use_focus_exit'
interface Props extends BoxProps {
  onExit?: () => void
  onSubmit: (e: React.FormEvent) => void
  onClearInput?: () => void
  placeholder: string
  allowEmptyValue?: boolean
  defaultValue?: string
}

const SearchBar = ({
  onExit,
  onSubmit,
  onClearInput,
  defaultValue,
  allowEmptyValue = false,
  placeholder,
  ...props
}: Props) => {
  const handleSubmit = (e: React.FormEvent) => onSubmit(e)
  const containerRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [isClearShown, showClear] = useState(false)

  useEffect(() => {
    searchInputRef.current?.focus()
  }, [searchInputRef])

  const onBlur = useFocusExit(containerRef, (_, inside) => {
    if (!inside && onExit) onExit()
  })

  const onClear = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = ''
      searchInputRef.current.focus()
      showClear(false)
      if (onClearInput) {
        onClearInput()
      }
    }
  }

  const onChange = () => {
    showClear(!!searchInputRef.current?.value)
  }

  const inputValue = searchInputRef.current?.value
  const previousInputValue = usePrevious(inputValue)

  useEffect(() => {
    // if user had typed a value and clear func is set
    // then we trigger clean function when user clears the search input
    if (onClearInput && previousInputValue && previousInputValue !== inputValue && inputValue === '') {
      onClearInput()
    }
  }, [onClearInput, previousInputValue, inputValue])

  return (
    <Flex
      as="form"
      direction="row"
      align="center"
      onBlur={onBlur}
      onSubmit={handleSubmit}
      {...props}
      ref={containerRef}
      borderWidth="0 0 2px 0"
      borderColor="dark_green"
      borderStyle="solid"
    >
      <Input
        type="search"
        size="md"
        placeholder={placeholder}
        flex="1 1 0%"
        name="keyword"
        variant="unstyled"
        ref={searchInputRef}
        onChange={onChange}
        defaultValue={defaultValue}
      />
      <Button onClick={onClear} visibility={isClearShown ? undefined : 'hidden'}>
        <Icon name="Close" size="4xs" mr={1} />
      </Button>

      <Button
        title="searchButton"
        isDisabled={!inputValue && !allowEmptyValue}
        disabled={!inputValue && !allowEmptyValue}
        mr="1"
        variant="ghost"
        border="solid 1px"
        borderColor={!inputValue ? 'main_gray_lighten' : 'green'}
        width="27px"
        height="27px"
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        type="submit"
      >
        <Search width="11px" color={!inputValue ? 'main_gray_lighten' : 'green'} />
      </Button>
    </Flex>
  )
}

export default SearchBar
