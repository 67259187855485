import { Box, Flex, BoxProps, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import type { FrontCollectTypeType } from '@miimosa/common/types'
import { getProjectStatusString } from '@miimosa/design-system/helpers/projects'

interface Props extends BoxProps {
  isSoon: boolean
  isDone: boolean
  isSuccess: boolean
  endsAt?: string | null
  collectType: FrontCollectTypeType
  progress: number | string
  interestsRatio?: number | null
  shouldDisplayDate?: boolean
}

const ProjectProgress: FC<Props> = ({
  isSoon,
  isDone,
  isSuccess,
  endsAt,
  collectType,
  interestsRatio,
  shouldDisplayDate = 'true',
  // progress,
  ...rest
}) => {
  const { t } = useTranslation('common')
  const isLending = collectType === 'lending'
  const projectColor = isLending ? 'blue' : 'green'
  const timingString = getProjectStatusString(isDone, isSoon, isSuccess, endsAt ? new Date(endsAt) : undefined, t)
  // const user = useContext(UserContext)
  const timingColor = (isSoon && 'orange') || ((isDone || isSuccess) && 'yellow') || projectColor
  const iconColor = ((isDone || isSuccess) && 'black') || 'white'
  // const shouldDisplayLock = isLending && !!progress && !!interestsRatio && !user && isCip
  const shouldDisplayRatio = isLending && !!interestsRatio

  return (
    <Flex align="center" height="26px" overflow="hidden" {...rest}>
      {shouldDisplayDate ? (
        <Flex
          borderLeft="solid 1px"
          borderTop="solid 1px"
          borderBottom="solid 1px"
          borderColor={timingColor}
          align="center"
          px="2"
          py="1"
          bgColor="white"
          borderBottomLeftRadius="4px"
          borderTopLeftRadius="4px"
          height="100%"
        >
          <Text size="xs">{timingString}</Text>
        </Flex>
      ) : (
        <Flex
          height="100%"
          borderLeft="solid 1px"
          borderTop="solid 1px"
          borderBottom="solid 1px"
          borderColor={timingColor}
          borderLeftRadius="4px"
          borderBottomLeftRadius="4px"
          borderTopLeftRadius="4px"
          w="4px"
        />
      )}
      <Flex
        align="center"
        px="2"
        borderTop="solid 1px"
        borderBottom="solid 1px"
        borderColor={timingColor}
        py="1"
        bgColor="white"
        height="100%"
      >
        <Box width="6px" height="6px" bgColor={timingColor} borderRadius="12px" />
        <Text ml="1" overflow="hidden" textOverflow="ellipsis" size="xs">
          {t(collectType === 'donation' ? 'donation_progress' : 'lending_progress')}
        </Text>
      </Flex>
      <Flex
        align="center"
        px="2"
        py="1"
        bgColor={timingColor}
        borderRight="solid 1px"
        borderTop="solid 1px"
        borderBottom="solid 1px"
        borderColor={timingColor}
        borderBottomRightRadius="4px"
        borderTopRightRadius="4px"
        height="100%"
      >
        {/* {shouldDisplayLock && <Lock mx="2" color={iconColor} />} */}
        {shouldDisplayRatio && (
          <Text color={iconColor} size="xs" px="2">
            {(interestsRatio * 100)?.toFixed(2).replace(/\./g, ',')}%
          </Text>
        )}
      </Flex>
    </Flex>
  )
}

export default ProjectProgress
