import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'

import { usePlatform } from '@miimosa/design-system'
import { Logo2 as Logo, Search } from '@miimosa/design-system/components/icons'
import Burger from '@miimosa/design-system/components/icons/Burger'
import UserContext from '@miimosa/design-system/components/UserContext'
// import { usePersistedState } from '@miimosa/design-system/lib/hooks'

import DesktopNav from './DesktopNav'
// import EventBanner from './EventBanner'
import MobileNav from './MobileNav'
import SearchBar from './SearchBar'

const DynamicHeaderAvatar = dynamic(() => import('./avatar'), { ssr: false })

const Header = ({ bgOpacity }: { bgOpacity: number }) => {
  const { platform, lang } = usePlatform()
  const { t } = useTranslation('common')
  const { query } = useRouter()

  const { isOpen, onClose, onToggle } = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  const [withSearchBar, showSearchBar] = useState(false)

  const hideWithSearchBar: { visibility: 'hidden' | undefined } = {
    visibility: withSearchBar ? 'hidden' : undefined,
  }

  const user = useContext(UserContext)
  const baseHeaderHeight = 72
  // const smallBannerHeight = 76
  const smallHeaderHeight = `${baseHeaderHeight}px`

  // const [eventBannerVisible, setEventBannerVisible] = usePersistedState('event_banner', false)

  const onSubmitSearch = (e: React.FormEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      keyword: { value: string }
    }
    window.location.href = `/${platform}/projects?utf8=✓&l=${lang}&keyword=${target.keyword.value}`
  }

  useEffect(() => onClose(), [onClose, query])

  return (
    <Box
      id="top"
      bgColor={isMobile && isOpen ? 'dark_green' : 'rgb(255 255 255/var(--header-bg-opacity))'}
      position="sticky"
      top="0"
      left="0"
      right="0"
      zIndex="1002"
      as="nav"
      sx={{
        '--header-bg-opacity': `${bgOpacity}`,
      }}
    >
      {/* {eventBannerVisible && !isMobile && (
        <EventBanner
          i18nKey="cashback"
          link="/cashback-a-impact"
          h={{ base: `${smallBannerHeight}px`, lg: '32px' }}
          onClose={() => setEventBannerVisible(false)}
        />
      )} */}
      <Flex
        height={`${baseHeaderHeight}px`}
        justifyContent={{ base: 'space-between', md: 'inherit' }}
        px={{ base: 0, md: '60px' }}
        alignItems="center"
        position="relative"
      >
        {!(isMobile && withSearchBar) && (
          <Box
            mx={{ base: 4, lg: 0 }}
            _focus={{
              outline: 0,
              boxShadow: 'none',
            }}
          >
            <NextLink href={'/'} aria-label="MiiMOSA home">
              <Logo color={isMobile && isOpen ? 'white' : 'dark_green'} h="26px" w="auto" />
            </NextLink>
          </Box>
        )}
        <Flex height="100%" width="100%" alignItems="center" justify="flex-end" position={{ lg: 'relative' }}>
          <DesktopNav flex="1 1 0%" {...hideWithSearchBar} display={{ base: 'none', lg: 'flex' }} />
          {!isOpen && (
            <Button
              title="searchButton"
              variant="ghost"
              border="solid 1px"
              borderColor={isMobile && isOpen ? 'white' : '#2A2A2A'}
              width="27px"
              height="27px"
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              ml="6"
              mr={{ base: '8px', md: '0' }}
              {...hideWithSearchBar}
              onClick={() => showSearchBar(true)}
            >
              <Search width="11px" color={isMobile && isOpen ? 'white' : 'black'} />
            </Button>
          )}

          {!withSearchBar && (
            <Button
              display={{ lg: 'none' }}
              onClick={onToggle}
              aria-label={'Toggle Navigation'}
              variant="ghost"
              size="iconMD"
              mr="8px"
            >
              <Burger isOpen={isOpen} color={isOpen ? 'white' : 'dark_green'} />
            </Button>
          )}
          {withSearchBar && (
            <SearchBar
              position="absolute"
              defaultValue={(query.keyword as string) ?? ''}
              w={{ base: 'calc(100% - 20px)', lg: '90%' }}
              h="44px"
              my="14px"
              mx={{ base: '10px', lg: '10px' }}
              top="0"
              right="0"
              onExit={() => showSearchBar(false)}
              onSubmit={onSubmitSearch}
              placeholder={t('header.search_placeholder')}
            />
          )}
          {!withSearchBar && <DynamicHeaderAvatar user={user} display={{ base: 'none', lg: 'flex' }} />}
          {isMobile && isOpen && (
            <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="full">
              <DrawerOverlay marginTop={smallHeaderHeight} />
              <DrawerContent marginTop={smallHeaderHeight} maxHeight={`calc(100% - ${smallHeaderHeight})`}>
                <DrawerBody padding="0">
                  <MobileNav user={user} />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default Header
