import { Flex, Heading } from '@chakra-ui/react'
import { useEffect } from 'react'

import type { FrontRewardType, FrontCollectGoalType, FrontCollectTypeType } from '@miimosa/common/types'

import { DefaultReward } from '@components'
import RewardItem from '@components/RewardItem'

type Props = {
  rewards: FrontRewardType[]
  isPreview: boolean
  slug: string
  collectGoalType: FrontCollectGoalType
  collectType: FrontCollectTypeType
}

const RewardList = ({ rewards, isPreview, slug, collectGoalType, collectType }: Props) => {
  useEffect(() => {
    if (rewards && rewards.length > 0) {
      rewards.sort((a, b) => Number(b.star) - Number(a.star))
    }
  }, [rewards])

  if (!rewards || rewards.length < 1) {
    return null
  }

  return (
    <Flex direction="column" rowGap={5}>
      <Flex w="full" alignItems="center">
        <Heading textAlign="center" w="full">
          Contreparties
        </Heading>
      </Flex>
      <Flex rowGap={5} px={{ base: 5, md: 0 }} direction="column">
        {rewards.map(
          (
            {
              amount,
              quantity,
              description,
              availableQuantity,
              deliveryAt,
              imageFileName,
              id: rewardId,
              title,
              shippingDetails,
              shippingMode,
              isAvailable,
              takenQuantity,
              star,
            },
            i
          ) => (
            <RewardItem
              key={`reward_${i}`}
              amount={amount}
              quantity={quantity}
              description={description}
              availableQuantity={availableQuantity}
              deliveryAt={deliveryAt}
              imageFileName={imageFileName}
              rewardId={rewardId}
              title={title}
              shippingDetails={shippingDetails}
              shippingMode={shippingMode}
              isPreview={isPreview}
              isAvailable={isAvailable}
              takenQuantity={takenQuantity}
              linkTo={`/projects/${slug}/pledges/new`}
              star={star}
            />
          )
        )}
      </Flex>
      {collectGoalType !== 'quantity' && (
        <Flex direction="column" alignItems="center" rowGap={4} width={{ base: '100%', lg: '330px' }}>
          <DefaultReward projectSlug={slug} isPreview={isPreview} collectType={collectType} />
        </Flex>
      )}
    </Flex>
  )
}

export default RewardList
