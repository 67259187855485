import { Avatar, BoxProps, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'

import type { FrontCollectTypeType } from '@miimosa/common/types'
import { usePlatform } from '@miimosa/design-system'

export interface Props {
  imageUrl?: string | null
  fullName?: string
  isSmall?: boolean
  place: string
  siret?: string | null
  bce?: string | null
  collectType?: FrontCollectTypeType | null
}

const Person: React.FC<Props & BoxProps> = ({
  imageUrl,
  fullName,
  isSmall,
  place,
  siret,
  bce,
  collectType,
  ...props
}) => {
  const { platform } = usePlatform()
  const { t } = useTranslation('common')
  const avatarSize = isSmall ? 'sm' : 'md'
  const textSize = isSmall ? 'sm' : 'md'
  const siretIsValidRegexp = /^[0-9]{8,}$/

  const Siret = ({
    siret,
    bce,
    collectType,
  }: {
    siret?: string | null
    bce?: string | null
    collectType?: FrontCollectTypeType | null
  }) => {
    // : collectType === 'lending' && t('siret_creation')
    return (
      <Flex direction="row" mt="0.5">
        {siret && siretIsValidRegexp.test(siret) ? (
          <Text size="sm">
            {t('siren').toUpperCase()}
            {siret.padEnd(9)}
          </Text>
        ) : (
          bce && (
            <Text size="sm">
              {t('bce').toUpperCase()}
              {bce}
            </Text>
          )
        )}
        {!siret && !bce && collectType === 'lending' && (
          <Text size="sm">
            {platform === 'fr' ? t('siren').toUpperCase() : t('bce').toUpperCase()}
            {t('siret_creation')}
          </Text>
        )}
      </Flex>
    )
  }

  return (
    <Flex align="center" direction="row" {...props} height="50px">
      <Avatar size={avatarSize} name={fullName} src={imageUrl ?? ''} loading="lazy" />
      <Flex direction="column" align="flex-start" ml="2">
        <Text
          overflow="hidden"
          height="fit-content"
          maxH={isSmall ? '31px' : '44px'}
          textOverflow="ellipsis"
          size={textSize}
          fontWeight="bold"
          textTransform="capitalize"
        >
          {fullName?.toLowerCase()}
        </Text>
        <Siret siret={siret} bce={bce} collectType={collectType} />
        <Text size={textSize} mt="0.5">
          {place}
        </Text>
      </Flex>
    </Flex>
  )
}

export default Person
